import { Button, Form, Input } from 'antd'
import React, { useState } from 'react'
import './css/loader.css'
import Api from "../common/Api"
import { useContext } from 'react'
import { context } from '../context'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { swalAlert } from '../common/utils'

// const { Tab, UserName, Password, Mobile, Captcha, Submit } = Login

const LoginPage = () => {
    const { setIsLoggedIn } = useContext(context)
    const [mobile, setMobile] = useState('')
    const [otp, setOtp] = useState('')
    const [isOtpSent, setIsOtpSent] = useState(false)
    const onClickSendOtp = async () => {
        if (!mobile) {
            return swalAlert('please enter number', false)
        }
        let result = await Api.sendOtp({
            mobile: `91-${mobile}`,
        })
        console.log(result)
        if (result && result?.status) {
            setIsOtpSent(true)
            swalAlert('otp sent successfully')
        }
        else {
            swalAlert(result?.message || 'internal server error', false)
        }
    }
    const onClickLogin = async () => {
        if (!mobile) {
            return swalAlert('please enter mobile', false)
        }
        else if (!otp) {
            return swalAlert('please enter otp', false)
        }
        let result = await Api.login({ mobile: `91-${mobile}`, otp })
        if (!result || !result?.status) {
            return swalAlert(result?.message || 'internal server error', false)
        }
        localStorage.setItem('userDetails', JSON.stringify({ userDetails: result.data }))
        localStorage.setItem('token', result.token)
        setIsLoggedIn(true)
        swalAlert(result?.message || 'login successful')
    }

    return (
        <>
            <div className='w-100 h-100 position-absolute' style={{ zIndex: 1, filter: 'blur(5px)', }}>
                <img src='/assets/images/bg-01.jpg' alt='' className='w-100 h-100' />
            </div>
            <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                zIndex: 2,
                minHeight: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
            }} className='w-100 mx-auto p-5'>
                <div className='container p-5 rounded' style={{
                    backgroundColor: 'white',
                    width: 'fit-content',
                    transform: "translate(-50%, -50%)",
                    position: 'absolute',
                    top: "50%",
                    left: "50%",
                }}>
                    <h2 className='text-center'>
                        <img src='/assets/images/LandWayLogo.svg' alt='' className='w-50' />
                    </h2>
                    <Form
                        name="normal_login"
                        className="login-form p-4"
                    >
                        <Form.Item
                            name="mobile"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Mobile!',
                                },
                            ]}
                        >
                            <Input value={mobile}
                                onKeyDown={() => false}
                                onChange={e => setMobile(String(e.target.value).replace(/[^0-9]/g, ''))}

                                prefix={<UserOutlined className="site-form-item-icon" />}
                                maxLength={10} placeholder="Mobile" />
                        </Form.Item>
                        {isOtpSent && <Form.Item
                            name="Otp"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Password!',
                                },
                            ]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="text" maxLength={6} placeholder="Otp" value={otp}
                                onChange={e => setOtp(String(e.target.value).replace(/[^0-9]/g, ''))}
                            />
                        </Form.Item>}
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="mx-auto rounded d-flex login-form-button" onClick={() => {
                                if (isOtpSent) {
                                    onClickLogin()
                                }
                                else {
                                    onClickSendOtp()
                                }
                            }}>
                                {isOtpSent ? 'Log in' : 'Send Otp'}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default LoginPage